<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="优惠券标题" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入优惠券标题"></a-input>
          </a-form-item>

          <a-form-item label="券类型" name="type" class="ui-form__item">
            <a-select v-model:value="formState.type" placeholder="请选择券类型" allow-clear style="width: 180px;">
              <a-select-option :value="1">代金券</a-select-option>
              <a-select-option :value="2">兑换券</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="赠送用户手机号" name="phone" class="ui-form__item">
            <a-input v-model:value="formState.phone" placeholder="请输入赠送用户手机号"></a-input>
          </a-form-item>

          <a-form-item label="券用途" name="useLimit" class="ui-form__item">
            <a-select v-model:value="formState.useLimit" placeholder="请选择券用途" allow-clear style="width: 180px;">
              <a-select-option :value="1">影片</a-select-option>
              <a-select-option :value="2">卖品</a-select-option>
              <a-select-option :value="3">商城</a-select-option>
              <a-select-option :value="4">演出</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="转增时间" name="receiveTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.receiveTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="接送用户手机号" name="senderPhone" class="ui-form__item">
            <a-input v-model:value="formState.senderPhone" placeholder="请输入接送用户手机号"></a-input>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="24" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 1350 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'type'">
              <div v-if="record.type === 1">代金券</div>
              <div v-if="record.type === 2">兑换券</div>
            </template>
            <template v-if="column.key === 'useLimit'">
              <div v-if="record.useLimit === 1">影片</div>
              <div v-if="record.useLimit === 2">卖品</div>
              <div v-if="record.useLimit === 3">商城</div>
              <div v-if="record.useLimit === 4">演出</div>
            </template>
            <template v-if="column.key === 'receiveTime'">
              {{ transDateTime(record.receiveTime) }}
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
</template>

<script>
import { getUserCouponChangeSendList } from "../../../../service/modules/coupon";

export default {
  components: {
  },
  data() {
    return {
      searchData: [],
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      formState: {

      },
      columns: [{
        title: '券标题',
        dataIndex: 'title',
      }, {
        title: '券类型',
        key: 'type',
      }, {
        title: '券用途',
        key: 'useLimit',
      }, {
        title: '赠送用户手机号',
        dataIndex: 'phone',
      }, {
        title: '转赠时间',
        key: 'receiveTime',
      }, {
        title: '接受用户手机号',
        dataIndex: 'receivePhone',
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.searchData.receiveTime && this.searchData.receiveTime.length === 2) {
        this.searchData.sendStartTime = this.moment(this.formState.receiveTime[0].startOf('day')).unix();
        this.searchData.sendEndTime = this.moment(this.formState.receiveTime[1].endOf('day')).unix();
      }
      delete this.searchData.receiveTime;
      this.loading = true;
      try {
        let ret = await getUserCouponChangeSendList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
